import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import moment from "moment";
import Img from "gatsby-image";
import { colors } from "../colors/colors";
import { Flex } from "@mantine/core";

const EventContainer = styled.div`
  cursor: pointer;
  color: ${colors.copy};
  ::after {
    content: "";
    width: 80%;
    height: 1px;
    margin: 5px auto;
    background-color: rgba(255, 255, 255, 0.1);
    display: block;
  }
`;

const EventGroup = styled.div`
  display: grid;
  /* padding: 15px; */
  grid-gap: 15px;
  grid-template-columns: 120px auto;
  justify-items: left left left;
  transition: 0.4s ease-out;

  @media (max-width: 600px) {
    grid-template-columns: 100px auto;
  }

  :hover {
    transform: translateX(15px);
    opacity: 0.9;
  }
`;

const EventFlyer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 2px;
  object-fit: cover;
  margin: 0;
  overflow: hidden;

  @media (max-width: 600px) {
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }
`;

const EventInfo = styled.div``;

const EventTitle = styled.h1`
  font-family: "Tanker";
  font-size: 25px;
  line-height: 100%;
  margin: 0;
  transition: 0.4s ease-out;
  margin-bottom: 8px;
  font-weight: 400;

  ${EventGroup}:hover & {
    color: ${colors.red};
  }
`;

const EventDescription = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 10px 0;
  line-height: 1.3;
  opacity: 0.8;
`;

const EventDate = styled.h2`
  font-size: 14px;
  font-weight: 500;
  color: #383838;
  margin: 0;
  margin-bottom: 8px;
  transition: 0.3s ease;
`;

class EventItem extends Component {
  render() {
    return (
      <>
        <EventContainer>
          <EventGroup>
            <EventFlyer>
              <Img
                style={{
                  width: "100%",
                  height: "100%",
                  margin: 0,
                }}
                fadeIn
                objectFit="cover"
                fluid={this.props.fluid}
              />
            </EventFlyer>

            <EventInfo>
              {this.props.url ? (
                <Link style={{ color: "inherit" }} to={this.props.url}>
                  <EventTitle>{this.props.title}</EventTitle>
                </Link>
              ) : (
                <EventTitle>{this.props.title}</EventTitle>
              )}
              <EventDescription>{this.props.description}</EventDescription>
              <EventDate>
                {moment(this.props.date).format("MMMM Do, YYYY")}{" "}
                {this.props.title === "Bacchanal Weekend (Combo)" &&
                  "- February 9th, 2020"}
              </EventDate>
              <Flex dir="row" gap={20} align="center">
                <Link
                  style={{
                    color: colors.red,
                    fontFamily: "Poppins",
                    fontSize: 15,
                    textDecoration: "underline",
                    fontWeight: 500,
                  }}
                  to={this.props.url}
                >
                  See details
                </Link>
                {this.props.ticketUrl && (
                  <a
                    style={{
                      color: colors.red,
                      fontFamily: "Poppins",
                      fontSize: 15,
                      textDecoration: "underline",
                      fontWeight: 500,
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.ticketUrl}
                  >
                    Get tickets
                  </a>
                )}
              </Flex>
            </EventInfo>
          </EventGroup>
        </EventContainer>
        <hr style={{ opacity: 0.3 }} />
      </>
    );
  }
}

export default EventItem;
