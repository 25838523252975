import React, { Component } from "react";
import Layout from "../components/layout";
import SectionIntro from "../components/section-intro";
import styled from "styled-components";

import EventItem from "../components/event-item";
import SEO from "../components/seo";
import { graphql, navigate } from "gatsby";
import Jumbotron from "../components/jumbotron";

const CalendarContainer = styled.div`
  max-width: 800px;
  padding: 50px 5%;
  margin: 0 auto;
`;

class EventCalendarPage extends Component {
  state = {
    events: [],
  };
  componentDidMount() {
    //Function to order by date
    const data = this.props.data.allWordpressPost.edges;

    const sortedData = data
      .filter((event) => {
        // Event date is in the future
        return new Date(event.node.acf.date) > new Date();
      })
      .sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.node.acf.date) - new Date(a.node.acf.date);
      });

    this.setState({ events: sortedData.reverse() }, () =>
      console.log(this.state)
    );
  }

  render() {
    const { events } = this.state;
    const jumboData = this.props.data.allWordpressPage.edges[0].node.acf;

    return (
      <Layout>
        <SEO
          title="Events Calendar"
          keywords={[
            `yup`,
            `life`,
            `FOC`,
            `Festival`,
            `Colour`,
            `Backyard`,
            `Melee`,
            `Events`,
            `Fete`,
            `Trinidad`,
            `Carnival`,
            `Party`,
            `Fete`,
          ]}
        />
        <Jumbotron slides={jumboData?.jumbotron_gallery ?? []} />
        <SectionIntro
          title={`${new Date().getFullYear()} Calendar of Events`}
          copy="The season is on. Find official ticket purchase links for our upcoming
          events"
        />
        <CalendarContainer>
          {events.map((event, index) => (
            <div
              key={index}
              onClick={() => navigate(`/events/${event.node.slug}`)}
            >
              <EventItem
                title={event.node.acf.name}
                description={event.node.acf.tagline}
                flyer={event.node.acf.flyer.source_url || ""}
                date={event.node.acf.date}
                slug={event.node.slug}
                fluid={event.node.acf.flyer.localFile.childImageSharp.fluid}
                ticketUrl={event.node.acf.ticket_url}
              />
            </div>
          ))}
        </CalendarContainer>
      </Layout>
    );
  }
}

export default EventCalendarPage;

export const eventsQuery = graphql`
  query ($homepage: String = "Home", $category: String = "Event") {
    allWordpressPage(filter: { title: { eq: $homepage } }, limit: 3) {
      edges {
        node {
          title
          acf {
            jumbotron_event
            jumbotron_subtitle
            jumbotron_gallery {
              url
              image {
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 2000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            jumbotron_cover {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxHeight: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          content
          slug
          acf {
            name
            flyer {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxHeight: 150) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            date
            ticket_url
            hide
            tagline
          }
        }
      }
    }
  }
`;
